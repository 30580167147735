import React from 'react';
import DocumentTitle from 'react-document-title';

function Index() {
    return (
        <DocumentTitle title="小青账-儿童隐私保护声明">
            <div className="home-page">
                <div className="home-page-wrapper">
                    <h1>儿童隐私保护声明</h1>
                    <br/>
                    <p>更新日期：2024年09月23日</p>
                    <p>生效日期：2024年09月23日</p>
                    <br/>
                    <h3>引言</h3>
                    <br/>
                    <p>小青账（以下简称我们）尊重并保护所有使用服务用户的个人隐私权。您在使用我们的服务时，我们可能会收集和使用您的相关信息。但小青账将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，小青账不会将这些信息对外披露或向第三方提供。小青账会不时更新本隐私权政策。
                        您在同意小青账服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于小青账服务使用协议不可分割的一部分。</p>
                    <br/>
                    <p>我们深知保护儿童（指未满十四周岁的未成年人，下同）个人信息安全和隐私的重要性。在<a
                        href={"/privacy"}>《隐私政策》</a>的基础上，我们希望通过《小青账儿童隐私保护声明》（以下简称「本政策」）说明我们在收集和使用儿童个人信息时对应的处理规则等相关事宜。您作为您孩子的父母或其他监护人（以下统称为“监护人”），请在您或您的孩子使用小青账的产品及服务（定义详见<a
                        href={"/privacy"}>《隐私政策》</a>，以下统称“我们的产品及服务”）前务必仔细阅读并透彻理解本政策，在确认充分理解并同意全部条款后再开始使用或允许您的孩子使用。
                    </p>
                    <h3>关于我们</h3>
                    <br/>
                    <p>小青账网站及APP的经营者为<strong>有钳智能科技（郑州）有限公司</strong>及其他关联公司。</p>
                    <p>联系地址：河南省郑州市高新技术产业开发区科学大道53号中原广告产业园7号楼2单元1层R08-596号</p>
                    <p>信息保护负责人：vczr@qq.com</p>
                    <br/>
                    <p><strong>本声明适用于14周岁以下的儿童用户的信息保护详细规则如下：</strong></p>
                    <br/>
                    <h3>1. 信息收集</h3>
                    <p>a) 在儿童使用产品和服务过程中，我们会要求监护人提供最少相关必要的信息，如果我们需要收集监护人或儿童的其他个人信息，我们将再次告知监护人和儿童，并征得监护人的同意。
                        监护人可以选择是否填写或向我们提供特定的信息。请您理解，监护人如果不填写或不提供某些特定的信息，可能会导致我们的产品和服务无法正常运行，或者无法实现我们拟提供的服务效果，监护人和儿童可能无法正常使用我们的产品、服务或相关的具体业务功能。</p>
                    <br/>
                    <h3>2. 信息使用</h3>
                    <p>a) 我们严格遵守法律法规的规定及与用户的约定，将收集的监护人和儿童的信息用于以下用途：</p>
                    <p>验证儿童和监护人身份。</p>
                    <p>提供必要的产品和服务、产品和服务的具体功能。</p>
                    <p>用于优化产品和服务、安全保障。</p>
                    <p>b)
                        如果我们需要超出上述用途使用监护人或儿童的个人信息，我们将再次告知监护人和儿童，并征得监护人的同意。</p>
                    <br/>
                    <h3>3. 管理、披露儿童个人信息</h3>
                    <p>我们遵照法律法规的规定，严格管理儿童的个人信息。</p>
                    <p>a) 我们仅在监护人事先同意并采取符合《个人信息保护法》等法律法规要求的相关合规措施的情况下，才可能与监护人共享儿童的个人信息:
                        例如，我们会将部分儿童个人信息（如儿童用户名、账号，以及该儿童在我们某些产品和服务中的支付记录等）与监护人进行共享，监护人可以访问和管理相关个人信息。</p>
                    <p>b)
                        发生合并、收购、资产转让等，随着我们业务的持续发展，当发生合并、收购、资产转让等交易导致向第三方共享或转移监护人和儿童的个人信息时，我们将通过推送通知、公告等形式告知监护人和儿童相关情形，按照法律法规及不低于本声明所要求的标准继续保护或要求新的管理者继续保护监护人和儿童的个人信息。</p>
                    <br/>
                    <h3>4. 如何存储儿童个人信息</h3>
                    <p>a)
                        小青账会采取加密等技术措施存储儿童个人信息，确保信息安全。小青账存储儿童个人信息，不会超过实现其收集、使用目的所必需的期限。</p>
                    <p>b)
                        当我们的产品和服务发生停止运营的情形或监护人和儿童的个人信息存储期限届满时，我们将采取例如，推送通知、公告等形式通知监护人和儿童，并在合理的期限内删除或匿名化处理监护人和儿童的个人信息。</p>
                    <br/>
                    <h3>5. 如何管理儿童的个人信息</h3>
                    <p>a) 为了您在使用我们的产品及服务期间可以更加便捷地访问和管理儿童的个人信息，同时保障注销账户的权利，我们在客户端中为监护人和儿童提供了相应的操作设置，监护人和儿童可以按照<a
                        href={"/privacy"}>《隐私政策》</a>中的“信息使用”章节指引进行操作。
                        下列情形下，监护人和儿童可以通过<a href={"/privacy"}>《隐私政策》</a>“关于我们”所列的渠道联系我们，要求删除相应的儿童个人信息：
                    </p>
                    <p> 我们违反法律、行政法规的规定或者双方的约定收集、存储、使用、对外提供儿童个人信息的；</p>
                    <p> 超出目的范围或者必要期限收集、存储、使用、对外提供儿童个人信息的；</p>
                    <p> 监护人撤回同意的；</p>
                    <p> 监护人或儿童通过注销等方式终止使用产品或者服务的。</p>
                    <br/>
                    <h3>6. 本政策如何更新</h3>
                    <p>由于本儿童隐私保护声明可能不定时更新，监护人应定期查看以了解我们保护所收集之个人信息的方案及程序。您继续使用小青账服务即构成您对本隐私政策及其任何更新版本的同意。</p>
                    <p>对于重大变更，视具体情况我们可能还会提供更为显著的通知说明本政策的具体变更内容。重大变更包括但不限于：</p>
                    <p>a) 我们的基本情况发生变化，例如：兼并、收购、重组引起的所有者变更；</p>
                    <p>b) 收集、存储、使用个人信息的范围、目的、规则发生变化；</p>
                    <p>c) 对外提供个人信息的对象、范围、目的发生变化；</p>
                    <p>d) 您访问和管理个人信息的方式发生变化；</p>
                    <p>e) 个人信息安全影响评估报告表明存在高风险时；</p>
                    <p>f) 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
                    <p>g) 其他可能对您的个人信息权益产生重大影响的变化。</p>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default Index;
